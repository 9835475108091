import React from "react";
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import type { PageSection } from 'gatsby-theme-portfolio-minimal/src/types';

import GoogleMap, { Marker } from './components/GoogleMap';

const AccessMapSection: React.FC<PageSection> = (props) => {
  const render = (status: Status) => <h1>{status}</h1>;

  const [zoom, setZoom] = React.useState(15); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 35.1761226,
    lng: 139.8179666,
  });

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    // setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  return (
    <Animation type="fadeUp">
      <Section anchor={props.sectionId} heading={props.heading}>
        <Wrapper apiKey="AIzaSyAN8sHU1SoNUoa6Rf0QE1F2FPbxEDuhhYE" render={render}>
          <GoogleMap
            style={{ width: '100%', height: '50vh' }}
            center={center}
            zoom={zoom}
            onClick={onClick}
            onIdle={onIdle}
          >
            <Marker
              title="有限会社FSK"
              position={{
                lat: 35.1761226,
                lng: 139.8179666,
              }}
            />
          </GoogleMap>
        </Wrapper>
      </Section>
    </Animation>
  );
}

export default AccessMapSection;
