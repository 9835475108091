import React from "react";
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';
import type { PageSection } from 'gatsby-theme-portfolio-minimal/src/types';

import CustomTable from './components/Table';

const items = [{
  title: '会社名',
  value: '有限会社 FSK',
}, {
  title: '住所',
  value: '〒299-1861 千葉県富津市金谷754-3',
}, {
  title: '電話番号',
  value: '0439-69-8098',
}, {
  title: 'FAX番号',
  value: '0439-69-8505',
}, {
  title: 'E-mail',
  value: 'fsk.sougoukikaku@road.ocn.ne.jp',
}, {
  title: '代表取締役',
  value: '福原正義',
}, {
  title: '取締役',
  value: '福原崇文',
}, {
  title: '設立',
  value: '2006年 4月 13日',
}, {
  title: '資本金',
  value: '3,000,000円',
}, {
  title: '工場地1',
  value: '千葉県南房総市宮谷120',
}, {
  title: '工場地2',
  value: '千葉県南房総市高崎194',
}];

const CompanySection: React.FC<PageSection> = (props) => {
  return (
    <Animation type="fadeUp">
      <Section anchor={props.sectionId} heading={props.heading}>
        <div className="flex justify-start">
          <CustomTable items={items} />
        </div>
      </Section>
    </Animation>
  );
}

export default CompanySection;
