import React from "react";
import { Page, Seo } from "gatsby-theme-portfolio-minimal";

import AccessMapSection from '../components/AccessMap';
import CompanySection from '../components/Company';

import '../index.css';

export default function CompanyPage() {
  return (
    <>
      <Seo title="有限会社 FSK" />
      <Page>
        <CompanySection sectionId="hoge" heading="会社情報" />
        <AccessMapSection sectionId="hoge" heading="アクセスマップ" />
      </Page>
    </>
  );
}
