import React from 'react';

interface Props {
  items: { title: string, value: string }[];
}

const CustomTable: React.FC<Props> = ({ items }) => {
  return (
    <table className="w-full table-auto">
      <tbody>
        {items.map((item, i) => (
          <tr key={i.toString()} className={(i % 2 === 1) ? "bg-gray-100" : ''}>
            <td className="border px-4 py-4">{item.title}</td>
            <td className="border px-4 py-4">{item.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
